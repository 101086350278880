import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import BlockContent from '../components/block-content'
import SEO from '../components/seo'

const query = graphql`
  query homePageQuery {
    sanityHome {
      pageContent: _rawPageContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default function HomePage() {
  const queryData = useStaticQuery(query)

  return (
    <Layout>
      <SEO title='Home'/>
      <BlockContent blocks={queryData?.sanityHome?.pageContent}/>
    </Layout>
  )
}
